
/* Navbar.css */
.navbar {
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: rgb(194, 211, 213);
  color: var(--title-color);

  z-index: 999;
  position: fixed !important;
  width: 100%;
}

.nav-links {
  font-family: Trirong, serif;
  list-style: none;
  display: flex;
  gap: 3rem;
  align-items: center;
}

h1 {
  color: #012650;
}

.nav-links a {
  text-decoration: none;
  color: var(--title-color);
}

.nav-links a.active {
  font-weight: bold;
  color: #012650;
}

.menu-icon {
  font-size: 1.5rem;
  cursor: pointer;
}

#aa {
  height: 4em;
}

#bb {
  padding-left: 2em;
  font-size: 35px;
  font-weight: 600;
  color: #012650;
  font-family: Trirong, serif;
  text-decoration: none; /* Add this line to remove underline */
}


#trip-btn {
  margin-right: 2em;
  text-decoration: none;
  background-color: #D7BA6A;
  color: white;
  border: 2px solid rgb(243, 216, 119);
  border-radius: 4px;
  transition: all 300ms ease-in-out;
  font-weight: 500;
}

@media (max-width: 470px) {
  #bb {
    font-size: 25px;
  }
  .nav-links {
    gap: 15px;
  }
  #trip-btn{
    width: 80px;
  }
  ul {
    padding-left: 20px;
    padding-right: 20px;
  }
}