main {padding-top: 150px;}

@media (max-width: 858px) {
main {
    padding-top: 175px;
}
}

@media (max-width: 490px) {
.top-padding {
    margin-bottom: 2em;
}
}