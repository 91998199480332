.footer-container {
  display: flex;
  width: 100%;

}

.footer-item {
  background-color: rgb(194, 211, 213);
  text-align: center;
  display: flex;
  width: 100%;
  padding-left: 1.5em;
  padding-right: 1.5em;
  height: auto;
  flex-direction: column;
  padding-bottom: 1em;
}

// social media
.mediaIcon-container {
  display: flex;
  justify-content: center;
  gap: 25px;
  width: 100%;
  align-items: center;
}


.icons2 {
  padding-top: 15px;
  display: flex;
  width: 25px;
}

.footer-p {
  padding-top: 15px;
  font-weight: 500;
  color: #012650;
}

a:link {
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .mediaIcon-container {
    gap: 5px;
    overflow: hidden;
    padding-bottom: 75px;
  }
  .icons2 {
    padding-top: 70px;
  }
  .footer-container {
    height: 100px;
  }
  .mediaIcons {
    // padding-bottom: 1.5em;
  }
}