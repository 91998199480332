.title-container {
  padding-top: 127px;
}

.title-p {
  color: #012650;
  text-align: center;
  margin-top: 1em;
  font-size: 36px;
  font-weight: 700;
}

.flex-container {
  padding-top: 2em;
  margin-bottom: 2em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border: 1px solid #012650;
  width: 30%;
  margin-left: 35%;
}

.inner-item-number {
  width: 12em;
  margin-bottom: 2%;
  position: relative;
}

.inner-item-text {
  width: 17em;
  margin-bottom: 2%;
  position: relative;
}

.pricing-number {
  font-size: 255px;
  text-align: center;
  color: #012650;
}

.pricing-title {
  font-size: 38px;
  color: #D7BA6A;
}

.pricing-text {
  font-weight: 500;
  color: #012650;
}

//smaller screen
@media (min-width:769px) and (max-width:1300px) {
  .title-p {
    margin-top: 2em;
  }
}

//Phone screen
@media only screen and (max-width: 768px) {
  .flex-container {
    width: 280px;
    margin: auto;
    width: 50%;
    padding: 10px;
    margin-bottom: 1em;
  }

  .title-container {
    padding-top: 50px;
    text-align: center;
  }

  .title-p {
    color: #012650;
    text-align: center;
    margin-top: 6em;
    font-size: 36px;
    font-weight: 500;
  }

  .pricing-title {
    text-align: center;
    font-size: 38px;
    color: #D7BA6A;
  }

  .pricing-number {
    font-size: 150px;
    overflow: hidden;
  }

  .pricing-text {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 500;
    color: #012650;
  }
}