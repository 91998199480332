@font-face {
  font-family: 'Abril Fatface', serif;
}

//cellphones
@media only screen and (max-width:481px) {
  .Computer {
    display: none;
  }
}

* {
  overflow-y: scroll;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}

//******** Computer ******

//This is the img container..
.image-container {
  height: fit-content;
}

.image {
  padding-top: 112px;
  height: 870px;
  //height: fit-content;
  overflow: hidden;
}

#main-img {
  width: 100%;
  height: auto;
}

.imageText-row {
  padding-top: 2em;
  box-sizing: border-box;
}

.imageText-column {
  display: flex;
  justify-content: space-around;
  float: left;
  width: 50%;
  padding: 10px;
}

div>h1 {
  font-size: 85px;
}

.item-1 {
  color: #012650;
  padding-left: 7%;
  align-items: center;
  font-weight: 900;
}

.item-2 {
  color: #012650;
  width: 500px;
  font-size: 22px;
  font-weight: 500;
}

.sub-titles {
  color: #012650;
  width: 100%;
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  padding-bottom: 1em;
}

// Icons****
.fa-icons {
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding-bottom: 3em;
}

.icons {
  color: #012650;
  font-weight: 500;
  height: 180px;
  width: auto;
  text-align: center;
}

//end of icons...

// Itineraries
.feature-itineraries {
  display: flex;
  width: 100%;
  gap: 1em;
  margin-bottom: 1em;
  padding-left: 1em;
  padding-right: 1em;
}

.feature-itineraries-img {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  background-color: rgb(194, 211, 213);
}

.itin-name {
  background-color: rgb(194, 211, 213);
  padding-bottom: 1em;
  color: #012650;
  text-align: center;
  font-size: 35px;
  font-weight: 500;
}

.itin-btn {
  font-size: 25px;
  align-content: center;
  text-decoration: none;
  background-color: #012650;
  padding: 8px 16px;
  color: white;
  border: 2px solid #012650;
  border-radius: 20px;
  //transition: all 300ms ease-in-out;
}

.itin-btn:hover {
  background-color: #1a419c;
  color: white;
}

@media (min-width:769px) and (max-width:1300px) {
.image {
  height: 500px;
}
.item-1 {
  font-size: 70px;
  font-weight: 900;
  overflow: hidden;
}
}

@media only screen and (max-width: 768px) {
  .image {
    padding-top: 0;
    height: 250px;
  }
  
  .fa-icons  {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .icons{
    flex: 50%;
  }

  .item-1 {
    font-size: 60px;
    font-weight: 900;
    overflow: hidden;
  }
  .fi-subtitle, .feature-itineraries {
    display: none;
  }

  svg {
    height: 100px;
  }

}

@media (max-width: 857px) {
  .imageText-row {
    padding-top: 13em;
  }
}

@media (max-width: 858px) {
  #main-img, .image {
      display: none;
  }
  .imageText-row {
    padding-top: 13em;
  }
}
